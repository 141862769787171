@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-brand-primary {
  background-color: rgba(var(--vc-colors-brand-primary))!important;
}

.bg-brand-secondary {
  background-color: rgba(var(--vc-colors-brand-secondary))!important;
}

.text-brand-primary {
  color: rgba(var(--vc-colors-brand-primary))!important;
}

.text-brand-secondary {
  color: rgba(var(--vc-colors-brand-secondary))!important;
}

.text-secondary {
  color: rgba(var(--vc-colors-text-secondary))!important;
}

.text-link {
  color: rgba(var(--vc-colors-text-link))!important;
}

.text-gray {
  color: rgba(var(--vc-colors-text-gray))!important;
}

.border-brand-primary {
  border-color: rgba(var(--vc-colors-brand-primary))!important;
}

.border-brand-secondary {
  border-color: rgba(var(--vc-colors-brand-secondary))!important;
}

.border-secondary {
  border-color: rgba(var(--vc-colors-secondary))!important;
}

.ring-brand-primary {
  --tw-ring-color: rgba(var(--vc-colors-brand-primary))!important;
}

.ring-brand-secondary {
  --tw-ring-color: rgba(var(--vc-colors-brand-secondary))!important;
}

.focus\:ring-brand-primary:focus {
  --tw-ring-color: rgba(var(--vc-colors-brand-primary))!important;
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
}